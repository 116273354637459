import React from 'react';
import TaimerComponent from "../../TaimerComponent";
// import List from "../../list/List";
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import DataHandler from '../../general/DataHandler';
import { SettingsList as List } from "../../list/lists/SettingsList";


import "../Settings.css";

import TrashCanIcon from "@mui/icons-material/Delete";
import ImportDialog from "../../dialogs/imports/ImportAccountDialog";
import CheckboxCell from "../../list/cells/CheckboxCell";
import RadioButtonCell from "../../list/cells/RadioButtonCell";
import TextInputCell from "../../list/cells/TextInputCell";
import ListCell from "../../list/ListCell";
import OneColumnSettingList from "../../list/lists/OneColumnSettingList";
import SettingRow from "../../list/rows/SettingRow";
import AccountImportTemplate from '../import-examples/Taimer_Account_Import_template.xlsx';
import OutlinedField from "./../../general/OutlinedField";
import ConfirmationDialog from "./../dialogs/ConfirmationDialog";
import SettingsGrid from './SettingsGrid';

import _ from "lodash";

import CustomFields from './CustomFields';

import StatusTag from '../../general/StatusTag';
import VersionContentManager from '../../general/VersionContentManager';

class AccountTypeSettingRow extends SettingRow {
    
    render() {
        const isAnyDefault = ["is_default", "default_subcontractor_type", "default_consumer_type"].filter(key => {
            return parseInt(this.state.data[key]) === 1 || this.state.data[key] === true;
        }).length > 0;

        const cells = {
            delete: <ListCell className="noBg pointer" permanentEditMode={true} width={this.props.columnWidthMap['delete']}>
                        {(isAnyDefault) ? undefined : <TrashCanIcon onClick=  {  () => this.delete(this.state.data) } />}
                    </ListCell>,
            english: <TextInputCell validation={["empty"]} onEdited={this.editAndSave} name="en" width={this.props.columnWidthMap['english']} value={this.state.data['en']} listCellProps={{ inEditMode: this.state.data.id < 0 }} focusOnMount={true} />,
            finnish: <TextInputCell validation={["empty"]} onEdited={this.editAndSave} name="fi" width={this.props.columnWidthMap['finnish']} value={this.state.data['fi']} listCellProps={{ inEditMode: this.state.data.id < 0 }} focusOnMount={true} />,
            swedish: <TextInputCell validation={["empty"]} onEdited={this.editAndSave} name="se" width={this.props.columnWidthMap['swedish']} value={this.state.data['se']} listCellProps={{ inEditMode: this.state.data.id < 0 }} focusOnMount={true} />,
            lithuanian: <TextInputCell validation={["empty"]} onEdited={this.editAndSave} name="lt" width={this.props.columnWidthMap['lithuanian']} value={this.state.data['lt']} listCellProps={{ inEditMode: this.state.data.id < 0 }} focusOnMount={true} />,
            default_customer_type: <RadioButtonCell disabled={this.state.data.id < 0} onEdited={(event, checked) => {
                this.editAndSave("is_default", checked);
            }} width={this.props.columnWidthMap['default_customer_type']} checked={this.state.data['is_default'] == 1} />,
            default_subcontractor_type: <RadioButtonCell disabled={this.state.data.id < 0} onEdited={(event, checked) => {
                this.editAndSave("default_subcontractor_type", checked);
            }} width={this.props.columnWidthMap['default_subcontractor_type']} name="default_subcontractor_type" checked={this.state.data['default_subcontractor_type'] == 1} />,
            default_consumer_type: <RadioButtonCell disabled={this.state.data.id < 0} onEdited={(event, checked) => {
                this.editAndSave("default_consumer_type", checked);
            }} width={this.props.columnWidthMap['default_consumer_type']} name="default_consumer_type" checked={this.state.data['default_consumer_type'] == 1} />,
            allow_invoicing: <CheckboxCell onEdited={checked => {
                this.editAndSave("show_billing", checked);
            }} width={this.props.columnWidthMap['allow_invoicing']} checked={this.state.data['show_billing'] == 1} /> 

        };

        return <div className="listElement row flex" style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;
    }
}

class AccountGroupRow extends SettingRow {
    render() {
        const cells = {
            delete: <ListCell className="noBg pointer" permanentEditMode={true} width={this.props.columnWidthMap['delete']}><TrashCanIcon onClick={() => this.delete(this.state.data)} /></ListCell>,
            name: <TextInputCell validation={["empty"]} onEdited={this.editAndSave} name="name" width={this.props.columnWidthMap['name']} value={this.state.data['name']} listCellProps={{ inEditMode: this.state.data.id < 0 }} focusOnMount={true} />,
            integration_id: <TextInputCell validation={["empty"]} onEdited={this.editAndSave} name="integration_id" width={this.props.columnWidthMap['integration_id']} value={this.state.data['integration_id']} />,
        }

        return <div className="listElement row flex" style={{ height: SettingRow.rowHeightPx, lineHeight: SettingRow.rowHeightPx }}>{this.props.columnOrder.map(columnName => cells[columnName])}</div>;

    }
}

class Account extends TaimerComponent {
    constructor(props, context) {
        super(props, context, "settings/pages/Account");

        this.accountTypeList = React.createRef();
        this.accountGroupList = React.createRef();
        this.enterpriseGroupList = React.createRef();
        this.accountTagList = React.createRef();

        this.state = {
            initialized: false,
            data: {
                customFields: [],
                accountTypes: [],
                accountGroupsAndTeams: [],
                enterpriseGroups: [],
                accountTags: [],
                customerNrMode: -1,
            },
            dialogData: undefined,
            accountGroupHeaderVisible:false,
            accountTypesHeaderVisible:false,
            active: {
                nav: false
            },
            useHubSpotCompanylessContacts: false,
            shareCustomerBasicForAll: false,
            preventCompanySpecificAutocompleteData: false,
            isSuperAdmin: false,
            addRelationToAllAccounts: false,
            shareAccountStructure: false,
            shareAddresses: false,
            allowAccountManagerAndResponsibleFromAllCompanies: false,
            forceCustomerShipGroup: false
        };

        this.dialogs = {
            confirmation: ConfirmationDialog,
            import: ImportDialog
        };

        this.setStateData = this.setStateData.bind(this);
        this.initData = this.initData.bind(this);
        this.fetchAccountTypes = this.fetchAccountTypes.bind(this);
        this.fetchAccountGroupsAndTeams = this.fetchAccountGroupsAndTeams.bind(this);
        this.fetchAccountTags = this.fetchAccountTags.bind(this);
        this.fetchIntegrationData =this.fetchIntegrationData.bind(this);
        
        this.customer_units = [
            {id: "-100", name: this.tr('All')},
            {id: "0", name: this.tr("Not in relation")},
            {id: "1", name: this.tr('Pääyksikkö')},
            {id: "2", name: this.tr('Aliyksikkö')}
		];


        this.initData();
    }

    openDialog = (name) => {
        this.setState({ currentDialog: name });
    }

    closeDialog = () => {
        this.setState({ currentDialog: false, dialogData: undefined });
        // this.fetchAccountTypes();
        // this.fetchAccountGroupsAndTeams();
    }

    confirmDialog = (saveFunc, id) => {
        saveFunc(id);
        this.closeDialog();
    }

    setStateData(key, data) {
        const stateData = this.state.data;
        stateData[key] = data;

        this.setState({ data: stateData });
    }


    initData() {
        this.fetchSuperAdminStatus()
        this.fetchAccountTypes();
        this.fetchAccountGroupsAndTeams();
        this.fetchEnterpriseGroups();
        this.fetchAccountTags();
        this.fetchIntegrationData();
        this.fetchaccountlistDefaultUnitFilter();
        this.fetchCustomerNrMode();
        this.fetchShareCustomerBasicForAll();
        this.fetchPreventCompanySpecificAutocompleteData();
        this.context.addons.hubspot && this.fetchHubSpotStatus();
        this.fetchAddRelationSetting();
        this.fetchShareAccountStructure();
        this.fetchShareAddressesSetting();
        this.fetchAllowAccountManagerAndResponsibleFromAllCompaniesSetting();
        this.getCustomFields();
        this.fetchForceCustomershipGroup();
    }

    componentDidUpdate(prevProps) {
        prevProps.company !== this.props.company && this.initData();
    }

    fetchSuperAdminStatus = () => {
        DataHandler.get({url: `settings/super_admin`}).done(response => {
            this.setState({isSuperAdmin: response.is_super_admin > 0});
        });
    }

    fetchIntegrationData(){
        const active = {
            nav: false
        };
        const { company } = this.props;
        return DataHandler.get({url: `settings/integrations/get/${company}`}).done(response => {
            _.forEach(response, api => {
                if(api.activated == 1){
                    active[api.api_name] = true;
                } else if (api.activated == -1){
                    active[api.api_name] = false;
                }
            })
            this.setState({active: active});
        });
    }


    fetchAccountTypes(callback = () => { }) {
        return DataHandler.get({url: `settings/account_types/${this.props.company}`}).done(data => {
             data.account_types.sort((a, b) => {
                const nameA =(a.en || a.fi || a.lt || a.se);
                const nameB =(b.en || b.fi || b.lt || b.se);
                const result = (nameA ? nameA : "").localeCompare(
                    nameB ? nameB : ""
                );
                if (result != 0) {
                  return result;
                }
                return (nameA ? nameA : "").localeCompare(nameB ? nameB : "");
              }); 
            this.setStateData("accountTypes", data.account_types);

            if(data.account_types.length == 0){
                this.setState({accountTypesHeaderVisible:false});
            }
            this.setState({initialized:true});
            callback();
        });
    }


    fetchAccountGroupsAndTeams(callback = () => { }) {
        DataHandler.get({url: `settings/customership_groups/${this.props.company}`}).done(data => {
            /* this.setStateData("accountGroupsAndTeams", data.customership_groups.sort((a, b) => { return b.id - a.id; })); */
            this.setStateData("accountGroupsAndTeams", data.customership_groups);
            if(data.customership_groups && data.customership_groups.length == 0){
                this.setState({accountGroupHeaderVisible:false});
            }
            callback();
        });
    }

    fetchEnterpriseGroups = () => {
        DataHandler.get({url: `settings/accounts/enterprise_groups/${this.props.company}`}).done(data => 
            this.setStateData("enterpriseGroups", data)
        );
    }
    
    fetchaccountlistDefaultUnitFilter = () => {
        DataHandler.get({url: `settings/accounts/accountlist_default_unit_filter/${this.props.company}`}).done(data => 
            this.setStateData("accountlist_default_unit_filter", data)
        );
    }    

    fetchAccountTags(callback = () => { }) {
        DataHandler.get({url: `settings/customer_tagpool/${this.props.company}`}).done(data => {
            this.setStateData("accountTags", data.customer_tagpool && data.customer_tagpool.sort((a, b) => { return b.id - a.id; }));
            callback();
        });
    }

    fetchCustomerNrMode = () => {
        const { company } = this.props;
        DataHandler.get({url: `settings/company/${company}/customer_nr_mode`}).done(data => {
            this.setStateData("customerNrMode", data);
        })
    }

    setCustomerNrMode = (evt) => {
        const { company } = this.props;
        DataHandler.post({url: `settings/company/${company}/customer_nr_mode`, activated: Number(evt)});
        this.setStateData("customerNrMode", evt);
    }

    fetchHubSpotStatus = () => {
        const { company } = this.props;
        DataHandler.get({url: `hubspot/${company}/settings`}).done(data => {
            this.setState({ useHubSpotCompanylessContacts: data.allow_companyless_hubspot_contacts });
        })
    }

    fetchShareCustomerBasicForAll = () => {
        DataHandler.get({url: `settings/accounts/share_basic_data`}).done(data => {
            this.setState({ shareCustomerBasicForAll: data.share_customer_basic_for_all > 0 });
        })
    }
    fetchPreventCompanySpecificAutocompleteData = () => {
        DataHandler.get({url: `settings/accounts/prevent_company_specific_autocomplete_data`}).done(data => {
            this.setState({ preventCompanySpecificAutocompleteData: data.prevent_company_specific_autocomplete_data > 0 });
        })
    }

    fetchAddRelationSetting = () => {
        const { company } = this.props;
        DataHandler.get({url: `settings/accounts/account_creation/${company}`}).done(data => {
            this.setState({ addRelationToAllAccounts: data.add_relation_to_all_accounts == 1 });
        })
    }

    fetchShareAccountStructure = () => {
        DataHandler.get({url: `settings/accounts/share_account_structure`}).done(data => {
            this.setState({ shareAccountStructure: data.share_account_structure == 1 });
        })
    }

    fetchShareAddressesSetting = () => {
        DataHandler.get({url: `settings/accounts/share_addresses`}).done(data => {
            this.setState({ shareAddresses: data.use_same_addresses_for_companies == 1 });
        })
    }

    fetchAllowAccountManagerAndResponsibleFromAllCompaniesSetting = () => {
        DataHandler.get({url: `settings/accounts/allow_account_manager_and_responsible_from_all_companies`}).done(data => {
            this.setState({ allowAccountManagerAndResponsibleFromAllCompanies: data.allow_account_manager_and_responsible_from_all_companies == 1 });
        })
    }
    
    fetchForceCustomershipGroup = () => {
        DataHandler.get({url: `settings/accounts/force_customership_group`}).done(data => {
            this.setState({ forceCustomerShipGroup: data.force_customership_group == 1 });
        })
    }

    getCustomFields = () => {
        const { company } = this.props;
        DataHandler.get({url: `settings/company/${company}/account/customfields`}).then(customFields => {
            this.setState({
                data: {
                    ...this.state.data,
                    customFields
                }
            });
        });
    }

    createCustomField = async (field) => {
        const { company } = this.props;
        const resp = await DataHandler.post({url: `settings/company/${company}/account/customfields`}, { customFields: [field] });
        const { customFields, report_overquota} = resp;
        if (report_overquota)
            this.props.enqueueSnackbar((this.tr("The limit of custom fields on reports is ") + report_overquota), {variant: "error"});
        this.setState({
            data: {
                ...this.state.data,
                customFields
            }
        });
    }

    onChangeCustomFields = (e, change) => {
        const { company } = this.props;
        const { target: { value } } = e;
        const showReportsCount = value.filter(vv => vv.show_on_reports > 0)?.length;
        this.setState({ data: { ...this.state.data, customFields: value } }, async () => {
            const resp = await DataHandler.post(
                { url: `settings/company/${company}/account/customfields` },
                { customFields: change || value }
            );
            const { customFields, report_overquota} = resp;
            if (report_overquota && showReportsCount > 3) {
                this.props.enqueueSnackbar((this.tr("The limit of custom fields on reports is ") + report_overquota), {variant: "error"});  
                change = undefined;     
            }
            if (!change && Array.isArray(customFields)) {
                this.setState({
                    data: {
                        ...this.state.data,
                        customFields
                    }
                });
            }
        });
    }

    render() {
        const dColConf = { resizeable: false, moveable: false, showMenu: false, showResizeMarker: false };
        const { tr } = this;
        const { company } = this.props;
        const {accountGroupHeaderVisible, accountTypesHeaderVisible, active, data: { customFields }} = this.state;
        const { taimerAccount: { isMulticompany } } = this.context;

        const Dialog = this.state.currentDialog ? this.dialogs[this.state.currentDialog] : undefined;
        const loaderStyle = {
            width: 60,
            height: 60,
            padding: 20,
          };
        const customer_nr_settings = [
			{
				type: "radiogroup",
				formLabel: "",
				defaultValue: 'normal_ids',
				value: this.state.data.customerNrMode,
				onChange: this.setCustomerNrMode,
				options: [
					{
						name: 'normal_ids',
						value: "-1",
						label: tr('Automatically generated Account Nr'),
						labelPlacement: 'start',
						className: 'respSetting half',
					},
					{
						name: 'custom_ids',
						value: "1",
						label: tr('Customisable Account Nr'),
						labelPlacement: 'start',
						className: 'respSetting half',
					},
					
                ] 
            }
        ];
        return (
            <div id="settings-feature-accounts">
                    {!this.state.initialized && 
                        <div> 
                            <img alt="" style={loaderStyle} src={require("../img/loading.svg").default}/>
                        </div>
                    }
                {this.state.initialized &&
                <React.Fragment>
                    {
                        isMulticompany && (
                            <div>
                                <h3>{tr("Installation wide settings")}
                                    <StatusTag
                                        style={{ marginLeft: 16, display: "inline", padding: "5px 12px" }}
                                        color={"#ffaf0f"}
                                        text={this.tr("Multi-Company")}
                                    />
                                </h3>
                                <p>{this.tr("Requires admin right for every company in installation")}</p>
                                <div className="SettingsCheck respSetting half">
                                    <div>{this.tr("Allow every user to see basic account information regardless of their account rights.")}</div>
                                    <Switch 
                                        color="primary" 
                                        checked={this.state.shareCustomerBasicForAll}
                                        disabled={!this.state.isSuperAdmin}
                                        onChange={(_, checked) => {
                                            DataHandler.post({ url: `settings/accounts/share_basic_data` }, {
                                                share_customer_basic_for_all: checked === true ? "1" : "0"
                                            }).done(response => {
                                                this.setState({
                                                    shareCustomerBasicForAll: response.share_customer_basic_for_all == 1
                                                });
                                            });
                                        }}
                                    />
                                </div>
                                <div className="SettingsCheck respSetting half">
                                    <div>{this.tr("Prevents users seeing other users, account managers, teams, account teams and enterprise grous from other companies in account list.")}</div>
                                    <Switch 
                                        color="primary" 
                                        checked={this.state.preventCompanySpecificAutocompleteData}
                                        disabled={!this.state.isSuperAdmin}
                                        onChange={(_, checked) => {
                                            DataHandler.post({ url: `settings/accounts/prevent_company_specific_autocomplete_data` }, {
                                                prevent_company_specific_autocomplete_data: checked === true ? "1" : "0"
                                            }).done(response => {
                                                this.setState({
                                                    preventCompanySpecificAutocompleteData: response.prevent_company_specific_autocomplete_data == 1
                                                });
                                            });
                                        }}
                                    />
                                </div>
                                <div className="SettingsCheck respSetting half">
                                    <div>{this.tr("Default account type set to accounts created in other companies")}</div> 
                                    <Switch 
                                        color="primary" 
                                        checked={this.state.addRelationToAllAccounts} 
                                        disabled={!this.state.isSuperAdmin}
                                        onChange={(_, checked) => {
                                            DataHandler.post({ url: `settings/accounts/account_creation/${company}` }, {
                                                add_relation_to_all_accounts: checked === true ? "1" : "0"
                                            }).done(response => {
                                                this.setState({
                                                    addRelationToAllAccounts: response.add_relation_to_all_accounts == 1
                                                });
                                            });
                                        }}
                                    />
                                </div>
                                <div className="SettingsCheck respSetting half">
                                    <div>{this.tr("Share account structure for all companies")}</div> 
                                    <Switch 
                                        color="primary" 
                                        checked={this.state.shareAccountStructure} 
                                        disabled={!this.state.isSuperAdmin}
                                        onChange={(_, checked) => {
                                            DataHandler.post({ url: `settings/accounts/share_account_structure` }, {
                                                share_account_structure: checked === true ? "1" : "0"
                                            }).done(response => {
                                                this.setState({
                                                    shareAccountStructure: response.share_account_structure == 1
                                                });
                                            });
                                        }}
                                    />
                                </div>
                                <div className="SettingsCheck respSetting half">
                                    <div>{this.tr("Share addresses with every company")}</div> 
                                    <Switch 
                                        color="primary" 
                                        checked={this.state.shareAddresses} 
                                        disabled={!this.state.isSuperAdmin}
                                        onChange={(_, checked) => {
                                            DataHandler.post({ url: `settings/accounts/share_addresses` }, {
                                                use_same_addresses_for_companies: checked === true ? "1" : "0"
                                            }).done(response => {
                                                this.setState({
                                                    shareAddresses: response.use_same_addresses_for_companies == 1
                                                });
                                            });
                                        }}
                                    />
                                </div>
                                <div className="SettingsCheck respSetting half">
                                    <div>{this.tr("Allow account manager and account responsible from all companies")}</div> 
                                    <Switch 
                                        color="primary" 
                                        checked={this.state.allowAccountManagerAndResponsibleFromAllCompanies} 
                                        disabled={!this.state.isSuperAdmin}
                                        onChange={(_, checked) => {
                                            DataHandler.post({ url: `settings/accounts/allow_account_manager_and_responsible_from_all_companies` }, {
                                                allow_account_manager_and_responsible_from_all_companies: checked === true ? "1" : "0"
                                            }).done(response => {
                                                this.setState({
                                                    allowAccountManagerAndResponsibleFromAllCompanies: response.allow_account_manager_and_responsible_from_all_companies == 1
                                                });
                                            });
                                        }}
                                    />
                                </div>
                                <div className="SettingsCheck respSetting half">
                                    <div>{this.tr("Make account group required")}</div> 
                                    <Switch 
                                        color="primary" 
                                        checked={this.state.forceCustomerShipGroup} 
                                        disabled={!this.state.isSuperAdmin}
                                        onChange={(_, checked) => {
                                            DataHandler.post({ url: `settings/accounts/force_customership_group` }, {
                                                force_customership_group: checked === true ? "1" : "0"
                                            }).done(response => {
                                                this.setState({
                                                    forceCustomerShipGroup: response.force_customership_group == 1
                                                });
                                            });
                                        }}
                                    />
                                </div>
                            </div>)
                    }
                    <div className={(!isMulticompany ? 'settings-feature-firstwrapper' : 'settings-feature-wrapper')}>
                        <h3>{tr("Edit Account Types for easy segmentation")}</h3>
                        <p style={{ fontSize: "11px" }}>{this.tr("Segment your accounts, customers, sub-contractors, new business, VIP’s and more simply by adding a new Account Types. You can even define if you want to restrict the creation of invoices to a specific Account Type. We have by default defined your Default customer type and Sub-contractor type. Sub-contractor type comes in handy when you activate the  billing feature.")}</p>
                        <Button className="green" onMouseUp={() => {this.accountTypeList.current.addNewRow();   this.setState({accountTypesHeaderVisible:true}); }} size="large">{tr("Add account type")}</Button>
                        <List
                            id="accountTypeList"
                            innerRef={this.accountTypeList}
                            fluid={true}
                            className="settingsList"
                            height="auto"
                            ignoreRowPropsChange={true}
                            rowHeight={SettingRow.rowHeight}
                            listRowType={AccountTypeSettingRow}
                            hideHeader={( accountTypesHeaderVisible || this.state.data.accountTypes.length > 0) ? false: true}
                            emptyNewDataOnUpdate={false}
                            newRow={{ english: "", finnish: "", swedish: "", lithuanian: "", default_customer_type: "0", default_subcontractor_type: "0", default_consumer_type: "0" }}
                            columns={[
                                { width: 4, name: "delete", header: "", ...dColConf },
                                { width: 15, name: "english", header: tr("Account Type"), ...dColConf },
                                /*   piilotettu tiketissä  TAIM9-305                 { width: 15, name: "finnish", header: tr("Finnish"), ...dColConf },
                                                        { width: 15, name: "swedish", header: tr("Swedish"), ...dColConf },
                                                        { width: 15, name: "lithuanian", header: tr("Lithuanian"), ...dColConf }, */
                                { alignment: "center", width: 14, name: "default_customer_type", header: tr("Default customer type"), ...dColConf },
                                { alignment: "center", width: 16, name: "default_subcontractor_type", header: tr("Default subcontractor type"), ...dColConf },
                                ...(this.context.addons.allow_consumer_customers || this.state.useHubSpotCompanylessContacts == 1 ? [{ alignment: "center", width: 14, name: "default_consumer_type", header: tr("Default consumer type"), ...dColConf }] : []),
                                { alignment: "center", width: 12, name: "allow_invoicing", header: tr("Allow invoicing"), ...dColConf },
                            ]}
                            rowProps={{
                                onCreate: data =>{
                                    let error = false;
                                        this.state.data.accountTypes.filter(a => {
                                            if(data && (a.id != data.id && a.en.toLowerCase() === data.en.toLowerCase()))
                                                error = true
                                        })
                                        if(data.en == ""){
                                            this.props.enqueueSnackbar((tr("Enter account type") + "."), {
                                                variant: "error",
                                            });
                                            return;
                                        }
                                        if (!error) {
                                            return DataHandler.post({ url: `settings/account_types/${data.id}/${company}`}, { data: JSON.stringify(data) }).done(() => {
                                                this.fetchAccountTypes(() => this.accountTypeList.current.removeNewRow(data.id));
                                            });
                                        }
                                        else{
                                            this.props.enqueueSnackbar(( data.en + tr(" Account type already exists") + "."), {
                                                variant: "error",
                                            });
                                            return;

                                        }
                                } ,
                                onUpdate: (data, row, list) => {
                                    if(data.id < 0) {
                                        return;
                                    }

                                    if (data.is_default === true || data.is_default === 1) {
                                        list.setData(list.getData().map(d => {
                                            d.is_default = d.id == data.id;

                                            return d;
                                        }));
                                    }

                                    if (data.default_subcontractor_type === true || data.default_subcontractor_type === 1) {
                                        list.setData(list.getData().map(d => {
                                            d.default_subcontractor_type = d.id == data.id;

                                            return d;
                                        }));
                                    }

                                    if (data.default_consumer_type === true || data.default_consumer_type === 1) {
                                        list.setData(list.getData().map(d => {
                                            d.default_consumer_type = d.id == data.id;

                                            return d;
                                        }));
                                    }

                                    DataHandler.post({ url: `settings/account_types/${data.id}/${company}`}, { data: JSON.stringify(data) }).done(() => {
                                        // this.fetchAccountTypes();
                                    });
                                },
                                onDelete: (data, _, list) => {
                                    if (Number(data.id) < 0) {
                                        list.removeRow(data.id);
                                        // this.accountTypeList.current.removeNewRow(data.id);
                                            if(this.state.data.accountTypes.length == 0){
                                                this.setState({accountTypesHeaderVisible:false});
                                            } 
                                        return;
                                    }
                                    this.setState({
                                        dialogData: {
                                            id: data.id,
                                            saveFunc: (id) => DataHandler.delete({ url: 'settings/account_types/' + id }, {company: company}).done(() => {
                                                // this.fetchAccountTypes();

                                                list.removeRow(data.id);
                                            }),
                                            text: this.tr("Do you want to delete account type") + ": " + data.en + "?"
                                        }
                                    }, () => this.openDialog('confirmation'));

                                }
                            }}
                            data={this.state.data.accountTypes} />
                </div>
                {!VersionContentManager.isFeatureHidden(this.namespace, 'accountGroups') && <div className="settings-feature-wrapper">
                    <h3>{this.tr("Account Groups")}</h3>
                    <p style={{ fontSize: "11px" }}>{tr("Create groups/teams to manage accounts, opportunities and sales to easily follow up on performance. You can define one account group per account. Account group filter can be found throughout the software.")}</p>
                    <Button className="green" onMouseUp={evt => {this.accountGroupList.current.addNewRow();  this.setState({accountGroupHeaderVisible:true});}} size="large">{tr("Add account group")}</Button>
                    <List
                        id="accountGroupList"
                        innerRef={this.accountGroupList}
                        className="settingsList integrationList"
                        height="auto"
                        rowHeight={SettingRow.rowHeight}
                        newRow={{
                            id: -1,
                            name: "",
                            integraion_id: "",
                        }}
                        hideHeader={( accountGroupHeaderVisible || (this.state.data.accountGroupsAndTeams && this.state.data.accountGroupsAndTeams.length > 0)) ? false: true}
                        listRowType={AccountGroupRow}
                        columns={[
                            { width: 55, name: "delete", header: "", ...dColConf },
                            { width: 350, name: "name", header: tr("Name"), ...dColConf },
                            { width: 350, name: "integration_id", visible: active.nav,  header: tr("Integration ID"), ...dColConf },
                        ]}
                        rowProps={{
                            onCreate: data => {
                                data.companies_id = this.props.company;
                    
                                let error = false;
                                this.state.data.accountGroupsAndTeams.filter(ag => {
                                    if(ag.id != data.id && ag.name.toLowerCase() === data.name.toLowerCase())
                                        error = true
                                })
                                if(data.name == ""){
                                    this.props.enqueueSnackbar((tr("Enter account group") + "."), {
                                        variant: "error",
                                    });
                                    return;
                                }
                                if (!error) {
                                    return DataHandler.post({url: `settings/customership_group/${data.id}`}, { data: JSON.stringify(data) }).done(() => {
                                        //this.fetchAccountGroupsAndTeams(() => this.accountGroupList.current.removeNewRow(data.id));
                                        // this.fetchAccountGroupsAndTeams();
                                    });
                                }
                                else{
                                    this.props.enqueueSnackbar(( data.name + tr(" Account group already exists") + "."), {
                                        variant: "error",
                                    });
                                    return;

                                }

                            },
                            onUpdate: data => DataHandler.post({url: `settings/customership_group/${data.id}`}, { data: JSON.stringify(data) }).done(() => {
                                // this.fetchAccountGroupsAndTeams();
                            }),
                            onDelete: (data, _, list) => {
                                this.setState({
                                    dialogData: {
                                        saveFunc: (id) => DataHandler.delete({url: `settings/customership_group/${data.id}`}).done(() => {
                                            // this.fetchAccountGroupsAndTeams();
                            
                                            list.removeRow(data.id); 
                                        }),
                                        text: tr("Do you want to delete account group") + ": " + data.name + "?"
                                    }
                                });
                                this.openDialog('confirmation');
                            }
                        }}
                        data={this.state.data.accountGroupsAndTeams} />
                </div>}

                {!VersionContentManager.isFeatureHidden(this.namespace, 'enterpriseGroups') && <div className="settings-feature-wrapper">

                    <h3>{this.tr("Enterprise groups")}</h3>
                    <p style={{ fontSize: "11px" }}>{tr("Enterprise groups header explanatory text.")}</p>
                    <Button className="green" onMouseUp={() => this.enterpriseGroupList.current.addNewRow()} size="large">{tr("Add enterprise group")}</Button>
                    <OneColumnSettingList
                        id="enterpriseGroupList"
                        key={this.state.data.enterpriseGroups.length}
                        ref={this.enterpriseGroupList}
                        company={company}
                        className="enterpriseGroupListHeight"
                        headerTitle={this.tr("Enterprise Group")}
                        rowProps={{
                            onCreate: data => {
                                let error = false
                                this.state.data.enterpriseGroups.filter(eg => {
                                    if(eg.id != data.id && eg.name.toLowerCase() === data.name.toLowerCase()){
                                        error = true
                                    }
                                })
                                if(data.name == ""){
                                    this.props.enqueueSnackbar((tr("Enter enterprise group") + "."), {
                                        variant: "error",
                                    });
                                    return;
                                }
                                if (!error) {
                                    return DataHandler.post({url: `settings/accounts/enterprise_group/${company}`}, {name:data.name}).done(() => {
                                        // this.fetchEnterpriseGroups();
                                    });
                                }
                                else{
                                    this.props.enqueueSnackbar(( data.name + tr(" Enterprise group already exists") + "."), {
                                        variant: "error",
                                    });
                                    return;

                                }
                            },
                        
                            onUpdate: data => DataHandler.put({url: `settings/accounts/enterprise_group/${company}`}, {name: data.name, id: data.id }).done(() => {
                                // this.fetchEnterpriseGroups();
                            }),
                            onDelete: (data, _, list) => {
                                if (Number(data.id) < 0) {
                                    list.removeRow(data.id);
                                    // this.enterpriseGroupList.current.removeNewRow(data.id);
                                    return;
                                }
                                this.setState({
                                    dialogData: {
                                        id: data.id,
                                        saveFunc: (id) => DataHandler.delete({ url: `settings/accounts/enterprise_group/${data.id}`}).done(() => {
                                            // this.fetchEnterpriseGroups();
                                        
                                            list.removeRow(data.id);
                                        }),
                                        text: tr("Do you want to delete the enterprise group") + ": " + data.name + "?"
                                    }
                                });
                                this.openDialog('confirmation');
                            }
                        }}
                        data={this.state.data.enterpriseGroups} />
                </div>}

                {!VersionContentManager.isFeatureHidden(this.namespace, 'subUnits') && <div className="settings-feature-wrapper">

                    <h3>{this.tr("Account list default customer unit setting")}</h3>
                    <p style={{ fontSize: "11px" }}>{tr("Account list default customer unit setting header explanatory text.")}</p>

                    <OutlinedField  label={this.tr("Customer unit")} 
                                    value={this.state.data.accountlist_default_unit_filter} 
                                    select
                                    className="accountlist-default-unit-filter"
                                    selectProps={{className: "accountlist-default-unit-filter"}}
                                    name="customer_unit"
                                    onChange={(event) => {
                                        const accountlist_default_unit_filter = event.target.value;
                                        DataHandler.put({ url: `settings/accounts/accountlist_default_unit_filter/${company}` }, {
                                            accountlist_default_unit_filter
                                        }).done(() => {
                                            this.setStateData("accountlist_default_unit_filter", accountlist_default_unit_filter);
                                        });
                                    }}>
                        { this.customer_units.map(row => (
                            <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                        ))}
                    </OutlinedField>
                </div>}

                {!VersionContentManager.isFeatureHidden(this.namespace, 'customId') && <div className="settings-feature-wrapper">                   
                    <h3>{this.tr("Account number mode setting")}</h3>
                    <p style={{ fontSize: "11px" }}>{tr("Defines whether Taimer uses automatically generated Account Nr's or manually given custom Account Nr's") + "."}</p>
                    <SettingsGrid settings={customer_nr_settings} item={this.state.data.customerNrMode} onChange={this.setCustomerNrMode} />
                </div>}
                {!VersionContentManager.isFeatureHidden(this.namespace, 'extraFields') && <div className ="settings-feature-wrapper">
                    <h3>{tr("Add new extra field to account details")}</h3>
                    <p className="subtitle">{tr("Define if your extra field is free text or dropdown. You can even make the extra field as a mandatory selector.")}</p>
                    
                    <CustomFields
                        name="customFields" 
                        value={customFields} 
                        onChange={this.onChangeCustomFields}
                        onCreateNew={this.createCustomField}
                        mode='account'
                        form={0} />
                </div>}
                {!VersionContentManager.isFeatureHidden(this.namespace, 'import') && <div className="settings-feature-lastwrapper">
                    <h3>{this.tr("Import Accounts")}</h3>
                    <p>
                        <span>
                            <a style={{ color: "#2D9FF7", fontSize: "11px" }} href={(this.context.userObject.language == 'fi' ? "https://psahelpcenter.heeros.com/hc/fi/articles/8080497191570" : "https://psahelpcenter.heeros.com/hc/en-us/articles/8080497191570")} target="_blank" rel="noreferrer">
                            {this.tr("Learn more about improting Accounts") + "."}
                            </a>
                        </span>
                    </p>
                    <p style={{ fontSize: "11px" }}>
                        <span>
                            {this.tr("Import Accounts XLSX format.")}
                        </span>
                        <span>
                            <a style={{ color: "#2D9FF7", marginLeft: "0.5em" }} href={AccountImportTemplate} download>
                            {this.tr("Download file template") + "."}
                            </a>
                        </span>
                    </p>
                    <Button className="green"  size="large" onMouseUp={() => {
                        this.fetchAccountTypes().done(
                            this.setState({ dialogData: this.state.accountTypes }, () => this.openDialog('import'))
                        )
                    }
                    }>{this.tr("Import Accounts")}</Button>
                </div>}
                </React.Fragment>
                }
                {Dialog && <Dialog
                    open
                    company={company}
                    onDialogClose={this.closeDialog}
                    onDialogSave={this.confirmDialog}
                    data={this.state.dialogData}

                />}
            
             </div>
        );
    }
}

export default Account;
