import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import './CalendarView.css';
import CalendarToolbar from './CalendarToolbar';
import DateCellWrapper from './Wrappers/DateCellWrapper';
import EventWrapper from './Wrappers/EventWrapper';
import ResourceDialog from '../../dialogs/ResourceDialog';
import TaimerAvatar from '../../general/TaimerAvatar';
import SidebarProject from './SidebarProject';
import TimeSlotWrapper from './Wrappers/TimeSlotWrapper';
import moment from "moment";
import SortableList from '../SortableList';
import { getColorClass, getColor } from './Colors.js';
import TaimerComponent from "../../TaimerComponent";
import TextBoxSuggestUser from '../TextBoxSuggestUser';
import { SettingsContext } from '../../SettingsContext';
import HeaderWrapper from './Wrappers/HeaderWrapper';
import ContextMenu from '../../general/ContextMenu';
import MenuItem from '@mui/material/MenuItem';
import _, { isEqual } from 'lodash';
import { withSnackbar } from 'notistack';
import DataHandler from './../../general/DataHandler'
import ColorBall from './ColorBall';
import DateHeader from './Wrappers/DateCellHeader'
import { format, isBefore } from "date-fns";


import { ReactComponent as RemoveIcon } from '../../general/icons/remove.svg';
import { ReactComponent as AssignTaskToUserIcon } from '../../general/icons/AssignTaskToUser.svg';
import { ReactComponent as DoNotAssignTaskToUserIcon } from '../../general/icons/DoNotAssignTaskToUser.svg';
import $ from 'jquery';
import ProjectTreeDropdown from '../../projects/ProjectTreeDropdown';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'

import { GetDateRange } from './helpers';

const CalendarContext = React.createContext({ hoverLocation: undefined });

// Calendar View Tab
class CalendarView extends TaimerComponent {
	static contextType = SettingsContext;

	static defaultProps = {
		minEventLen: 15,
		defaultEventLen: 60,
		allDayStartHour: 8,
		allDayEndHour: 16,
		calendarType: Calendar,
		sidebarProjects: [],
		onSidebarProjectsChanged: (list) => { },
		sidebarUsers: [],
		preventMulticompanyHourentries: false,
		onSidebarUsersChanged: (list) => { },
		workhourbalances: {},
		parentComponent: false,
	}

	constructor(props, context) {
		super(props, context, "workhours/time-tracker/CalendarView");

		const { functions, calendar, timeTracker } = context;
		
		this.localizer = dateFnsLocalizer({
			format,
			parse,
			startOfWeek: () => {
				return startOfWeek(new Date(), { weekStartsOn: calendar.startOfWeek });
			},
			getDay,
			locales: functions.getFnsLocales()
		});

		this.updateEvent = this.updateEvent.bind(this);
		this.onEventResize = this.onEventResize.bind(this);
		this.onEventDrop = this.onEventDrop.bind(this);
		this.getEventProps = this.getEventProps.bind(this);
		this.onNavigate = this.onNavigate.bind(this);
		this.onViewChange = this.onViewChange.bind(this);
		this.onSelectSlot = this.onSelectSlot.bind(this);
		this.onDoubleClick = this.onDoubleClick.bind(this);
		this.onClick = this.onClick.bind(this);
		this.prefetchProjects = this.prefetchProjects.bind(this);
		this.projectsExpandedKey = "calendarViewProjectsExpanded";
		this.usersExpandedKey = "calendarViewUsersExpanded";
		this.headerRefs = {};
		this.drawingStartPoint = undefined;

		let projectsExpanded = true;
		let usersExpanded = true;

		try {
			const LSProjectsExpanded = localStorage.getItem(this.projectsExpandedKey) || '1';
			const LSUsersExpanded = localStorage.getItem(this.usersExpandedKey) || '1';
			projectsExpanded = LSProjectsExpanded == '1';
			usersExpanded = LSUsersExpanded == '1';
		} catch (e) {
			console.error(e);
		}

		this.state = {
			selectable: true,
			resizable: true,
			events: [],
			currentView: "week",
			currentDate: moment().endOf("week").toDate(),
			sidebarFilter: "",
			projectsExpanded,
			usersExpanded,
			preFetchedProjects: [],
			hoverLocation: undefined,
		};

		const allowed = [5, 6, 10, 12, 15, 20, 30, 60];

		this.accurary = allowed.reduce(
			(prev, curr) => (Math.abs(curr - timeTracker.workhour_accuracy) < Math.abs(prev - timeTracker.workhour_accuracy) ? curr : prev));
	}

	componentDidMount = () => {
		super.componentDidMount();
		this.requestDataUpdate();
		this.timeView = document.querySelector(".rbc-time-view");
		
		this.timeView.addEventListener("mousemove",this.shouldScrollListener);
	    window.addEventListener("mouseup", this.clearScrollInterval);
		document.addEventListener('keydown', this.onKeyDown);

		this.prefetchProjects();
	}

	componentDidUpdate = (oldProps) => {
		if (!isEqual(oldProps.events, this.props.events)) {
			let events;
			if(this.state.currentView != 'month') {
				events = this.props.events.filter(el => !(el.is_task > 0 && el.is_one_day_task < 1));
			} else {
				events = this.props.events;
			}
			Object.values(this.headerRefs || {}).forEach(header => header?.setEvents && header.setEvents(events))
		}
	}

	componentWillUnmount = () => {
		this.timeView.removeEventListener("mousemove", this.shouldScrollListener);
	    window.removeEventListener("mouseup", this.clearScrollInterval);
		document.removeEventListener("keydown", this.onKeyDown)
	}

	shouldScrollListener = (e) => {
		const rect = this.timeView.getBoundingClientRect();
		const y = e.clientY - rect.top;
		this.shouldScrollDown = this.timeView.offsetHeight - y < 30;
		this.shouldScrollUp = y < 100;
	}	

	cancelHoverLocation = () => {
		this.hoverLocationTimer = setTimeout(() => {
			this.setState({ hoverLocation: undefined });
		}, 100);
	}

	setHoverLocation = (hoverLocation) => {
		if (this.hoverLocationTimer) {
			clearTimeout(this.hoverLocationTimer);
			this.hoverLocationTimer = null;
		}
		if ((this.state.hoverLocation?.hours != hoverLocation.hours || this.state.hoverLocation?.minutes != hoverLocation.minutes)) {
			this.setState({ hoverLocation });
		}
	}

	onSelectingEvent = (range) => {

		if (!this.drawingStartPoint) {
			this.drawingStartPoint = range.start;
		}

		const endPoint = isBefore(range.start, this.drawingStartPoint) ? range.start : range.end;
		const hours = endPoint.getHours();
		const minutes = endPoint.getMinutes();

		if (endPoint.getHours() != this.state.hoverLocation?.hours || endPoint.getMinutes() != this.state.hoverLocation?.hours) {
			this.setHoverLocation({ hours, minutes });
		}

		if(!this.scrollInterval) {
			this.scrollInterval = setInterval(() => {
				if(this.shouldScrollDown) {
					this.timeView.scrollBy(0, 10);
				} else if(this.shouldScrollUp) {
					this.timeView.scrollBy(0, -10);
				}
			}, 50);
		}
	}

	clearScrollInterval = () => {
    	if(this.scrollInterval) {
    		clearInterval(this.scrollInterval);
    		this.scrollInterval = false;
    	}
	}

	showEventCreate = (start, end) => {
		const round = (end.getTime() % 60000);

		if (round > 0)
			end = new Date(end.getTime() + 60000 - round);

		let event;
		if(this.props.createTasks) {
			const hours = (end - start) / (1000 * 60 * 60);
			const usersHours = [];
			const sidebarUsers = [...this.props.sidebarUsers];
			let id = -1;
			sidebarUsers.forEach((el) => {
				if(el.assignee) {
					if(el.user_ids) {
						el.user_ids.forEach(ell => {
							if(!usersHours.find(x=>x.users_id == ell)){
								usersHours.push({id: id, users_id: ell, hours: hours, hours_done: 0});
								id--;
							}
						})
					} else {
						if(!usersHours.find(x=>"u-"+ x.users_id == el.id)){
							usersHours.push({id: id, users_id: (el.id + "").replace(/[^0-9]/g,""), hours: hours, hours_done: 0});
							id--;
						}
					}
				}
			});
			event = {
				start_date: moment(start).format("YYYY-MM-DD"),
				starttime: moment(start).format("HH:mm"),
				end_date: moment(end).format("YYYY-MM-DD"),
				endtime: moment(end).format("HH:mm"),
				hours: (end - start) / (1000 * 60 * 60),
				$new: true,
				is_task: 1,
				type: 'task',
				onlyTask: true,
				users_hours: usersHours.length > 0 ? usersHours : false
			}
			this.openEditSlider(event);
		} else {
			this.openEditSlider({ start, end });
		}
	}

	quickCreateEvent = async (template, start, end, allDay) => {
		const { functions: { getTimeTrackerSettings } } = this.context;
		const timeTrackerSettings = getTimeTrackerSettings();

		if (allDay) {

			allDay = false;

			start.setHours(0, 0, 0);

			const midnight = start.getTime();

			start = new Date(midnight + (this.props.allDayStartHour * 60000 * 60));
			end = new Date(midnight + (this.props.allDayEndHour * 60000 * 60));
		}

		else if (start.getTime() === end.getTime()) {
			end = new Date(start.getTime() + (this.props.defaultEventLen * 60000))
		}

		const minLen = this.props.minEventLen * 60000;
		const eventLen = end.getTime() - start.getTime();

		if (eventLen < minLen) {
			end = new Date(start.getTime() + minLen);
		}

		end = new Date(end.getTime() + (end.getTime() % 60));

		if (end.getDate() !== start.getDate())
			end = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 23, 59, 0);


		let event;
		if(this.props.createTasks) {
			const hours = (end - start) / (1000 * 60 * 60);
			const usersHours = [];
			const sidebarUsers = [...this.props.sidebarUsers];
			let id = -1;
			sidebarUsers.forEach((el) => {
				if(el.assignee) {
					if(el.user_ids) {
						el.user_ids.forEach(ell => {
							usersHours.push({id: id, users_id: ell, hours: hours});
							id--;
						})
					} else {
						usersHours.push({id: id, users_id: (el.id + "").replace(/[^0-9]/g,""), hours: hours});
						id--;
					}
				}
			});

			event = {
				start_date: moment(start).format("YYYY-MM-DD"),
				starttime: moment(start).format("HH:mm"),
				end_date: moment(end).format("YYYY-MM-DD"),
				endtime: moment(end).format("HH:mm"),
				start: start,
				end: end,
				hours: hours,
				$new: true,
				is_task: 1,
				type: 'task',
				projects_id: template && template.project ? template.project.id : null,
				onlyTask: true,
				users_id: this.props.defaultUser,
				users_hours: usersHours
			}
		} else {
			event = {
				...template,
				id: null,
				users_id: this.props.defaultUser,
				projects_id: template && template.id,
				start: start,
				end: end,
				allDay: allDay,
			};

			if (timeTrackerSettings.hour_entry_description) {
				this.openEditSlider(event);
				return;
			}
		}

		const data = await this.props.onSaveEvent(event);

		if (data && data.action === 'open_edit') {
			this.openEditSlider(event);
		}
	}

	async updateEvent(event, start, end, allDay) {
		if (event.saving) return;
		// Dropped to original position, skip edits
		if (event.start.getTime() === start.getTime() && event.end.getTime() === end.getTime())
			return;

		end = new Date(end.getTime() + (end.getTime() % 60));

		if (end.getDate() !== start.getDate())
			end = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 23, 59, 0);
		let eventNew;
		if(event.is_task > 0) {
			eventNew = {
				...event,
				start_date: moment(start).format("YYYY-MM-DD"),
				starttime: moment(start).format("HH:mm"),
				end_date: moment(end).format("YYYY-MM-DD"),
				endtime: moment(end).format("HH:mm"),
				hours: (end - start) / (1000 * 60 * 60),
				start: start,
				end: end,
			};
		} else {
			eventNew = {
				...event,
				start: start,
				end: end,
				allDay: false,
			};
		}

		const data = await this.props.onSaveEvent(eventNew);

		if (data && data.action === 'open_edit') {
			this.openEditSlider(eventNew)
		}
	}

	onEventResize = ({ event, start, end, allDay }) => {
		const minLen = this.props.minEventLen * 60000;
		const eventLen = end.getTime() - start.getTime();

		if (eventLen < minLen) {
			end = new Date(start.getTime() + minLen);
		}

		if (start.getDate() !== end.getDate()) {
			if (event.start.getTime() !== start.getTime())
				end = new Date(start.getTime() + eventLen);
			else if (event.end.getTime() !== end.getTime())
				start = new Date(end.getTime() - eventLen);
		}

		this.updateEvent(event, start, end, allDay);
	};

	onEventDrop = ({ event, start, end, allDay }) => {
		if (!("id" in event)) {
			this.quickCreateEvent(event, start, end, allDay);
		}
		else {
			this.updateEvent(event, start, end, allDay);
		}
	};

	getEventProps(event, start, end, isSelected) {
		const len = end.getTime() - start.getTime();
		let force = false;
		if (event.isWorktripProject) force = true;

		return {
			style: {},
			className: cn(getColorClass(event, this.props.colorsToUse, force), len <= (this.accurary * 60000 * 2) && " event-short", event.is_task && 'task-event'),
		};
	}

	onNavigate(date, view) {
		if (this.props.loading) return;
		
		const currentDate = date;

		this.setState({ currentDate, currentView: view });

		this.requestDataUpdate(false, currentDate, view);
	}

	onViewChange(view) {
		const { currentView } = this.state;
		let { currentDate } = this.state;

		const { start, end } = GetDateRange(currentView, currentDate); 

		const today = moment();

		if (view === "day" && today.isAfter(start) && today.isBefore(end)) {
			currentDate = today.toDate();
		}
		
		this.setState({ selectable: view !== "month", resizable: view !== "month", currentDate, currentView: view }, () => {
			this.requestDataUpdate(false, currentDate, view);
		});
	}

	getSelectedDateRange = (noDateFormatting = false) => {
		const { currentView } = this.state;
		const { currentDate } = this.state;

		const { start, end } = GetDateRange(currentView, currentDate, true);

		return {
			startDate: noDateFormatting ? start : format(start, "YYYY-MM-DD"),
			endDate: noDateFormatting ? end : format(end, "YYYY-MM-DD")
		}
    }

	openEditSlider = (entry) => {
		if (this.props.openEditSlider) {
			this.props.openEditSlider(entry);
			return;
		}
		if (entry.is_task > 0) {
			this.context.functions.addResource(entry);
		} else {
			this.context.functions.addHours(entry, { confirmHourSubmit: this.confirmHourSubmit, getLastAddedEvent: this.props.getLastAddedEvent, onEntryChanged: this.saveHourEntry, parentComponent: (this.props.parentComponent + " calendar"), workdays: this.props.workdays });
		}
	}

	onDoubleClick(e) {
		if (e.saving) return;
		this.openEditSlider(e);
	}

	onSelectSlot(slotInfo) {
		const { functions: { getTimeTrackerSettings } } = this.context;
		const timeTrackerSettings = getTimeTrackerSettings();

		const { enqueueSnackbar } = this.props;
		this.clearScrollInterval();
		this.drawingStartPoint = undefined;

		if (slotInfo.action === "select") {

			if (slotInfo.start < timeTrackerSettings.allow_add_after) {
				enqueueSnackbar(this.tr("Entering hours to locked month not possible!"), {
					variant: "error",
				});
				return;
			}

			this.showEventCreate(slotInfo.start, slotInfo.end);
		}
	}

	saveHourEntry = (item) => {
		this.props.onAddEvent && item && this.props.onAddEvent(item);
	}

	async prefetchProjects() {
		const allProjects = await DataHandler.get({ url: `projects/dropdown` });	

		this.setState({ 
			prefetchedProjects: allProjects
		});
	}

	requestDataUpdate(force = false, date = this.state.currentDate, view = this.state.currentView) {
		const { userObject } = this.context;
		const { onViewChange, onRequestData } = this.props;

		const { start, end } = GetDateRange(view, date);
		const { start: startMonth, end: endMonth } = GetDateRange(view, date, true);

		onViewChange?.(view, startMonth.toDate(), endMonth.toDate());
		onRequestData?.(start, end, force);
	}

	getProjectHours = (project) => {
		if (!this.props.hoursSheetProject)
			return 0;

		const p = this.props.hoursSheetProject[project.id];

		if (!p)
			return 0;

		return p["totalHours"] || 0;
	}

	addProject = (project) => {
		this.onSidebarAddProject(project);
	}

	hideProject = (project) => {
		this.onSidebarRemoveProject(project);
	}

	onOrderChanged = (list) => {
		this.props.onSidebarProjectsChanged(list);
	}

	addUser = (user) => {
		if (this.props.sidebarUsers.indexOf(user) > -1)
			return;

		this.props.onSidebarUsersChanged([...this.props.sidebarUsers, user]);
	}

	hideUser = (user) => {
		const sidebarUsers = [...this.props.sidebarUsers];

		let index = -1;
		for(let i = 0; i < sidebarUsers.length; i++) {
			if(sidebarUsers[i].id == user.id) {
				index = i;
				break;
			}
		}

		if (index > -1)
			sidebarUsers.splice(index, 1);

		this.props.onSidebarUsersChanged(sidebarUsers);
	}

	onUserOrderChanged = (list) => {
		this.props.onSidebarUsersChanged(list);
	}
    
    showProjects = async (e) => {
		e.preventDefault();

		const data = await DataHandler.get({url: 'timetracker/defaultProjects'});

		this.props.refreshSidebarProjects({projects: data});
	}

	filterSidebar = (e) => {
		this.setState({ sidebarFilter: e.target.value });
	}

	changeTaskUserAssign = (user, assign) => {
		const sidebarUsers = [...this.props.sidebarUsers];

		let index = -1;
		for(let i = 0; i < sidebarUsers.length; i++) {
			if(sidebarUsers[i].id == user.id) {
				index = i;
				break;
			}
		}

		if (index > -1) {
			sidebarUsers[index].assignee = assign;
		}
		this.props.onSidebarUsersChanged(sidebarUsers);
	}

	onSelectProject = e => {
		const { data } = e;
		data && this.props.onSidebarAddProject(data);
	}

	confirmHourSubmit = async (type, id) => {
        const fetchData = {
            ids: [id],
            name: "status",
            type
        }

        let checkedData = {};
        try {
            checkedData = await DataHandler.post({ url: `timetracker/workhours/check_massedit` }, { data: fetchData, type: type});
        } catch (e) { }      
        
        this.props.openSubmitHoursDialog("myHours", type, {ids: [id], ...checkedData});
	}
	
	onProjectDragStart = (e, item) => {
		const project = {id: item.id, name: item.name}
		const entry = {
			customer: item.customer,
			unit: item.unit,
			project: project,
			wh_projects_resource: { id: 0 },
		}
		e.dataTransfer.setData("event", JSON.stringify(entry));
	}

	toggleProjectSection = () => {
		this.setState({ projectsExpanded: !this.state.projectsExpanded }, () => {
			try {
				localStorage.setItem(this.projectsExpandedKey, this.state.projectsExpanded ? '1' : '0')
			} catch (e) {
				console.error(e);
			}
		});
	};
	toggleUserSection = () => {
		this.setState({ usersExpanded: !this.state.usersExpanded }, () => {
			try {
				localStorage.setItem(this.usersExpandedKey, this.state.usersExpanded ? '1' : '0')
			} catch (e) {
				console.error(e);
			}
		});
	};

	onClick = (e, event) => {
		if (e.saving) return;
		const checkClickArea = event.target.className.includes('edit-entry')
		if(checkClickArea){
			this.openEditSlider(e);
		}
		return
	}

	onKeyDown = e => {
		if (e.key === "Escape" && this.drawingStartPoint) this.drawingStartPoint = undefined;
	};

	render() {
		const DnDCalendar = this.props.calendarType;
		const { userObject, timeTracker, functions: { checkPrivilege, hasPrivilege } } = this.context;
		const { sidebarFilter, projectsExpanded, usersExpanded } = this.state;
		const { lastEntry, noSidebar, workhourbalances, overtime_hours_activated } = this.props;
		const { tr } = this;
		const slotsInHour = 60 / this.accurary;
		const slots = Math.min(Math.ceil(slotsInHour), 12);
		const isExact = slotsInHour * this.accurary === 60;
		this.headerRefs = {};

		let events;
		if(this.state.currentView != 'month') {
			events = this.props.events.filter(el => !(el.is_task > 0 && el.is_one_day_task < 1));
		} else {
			events = this.props.events;
		}

		const button = {
			className: 'list-menu',
			stickyIcon: true,
		};

		const messages = {
			date: tr('Date'),
			time: tr('Time'),
			event: tr('Event'),
			allDay: tr('All Day'),
			week: tr('Week'),
			work_week: tr('Work Week'),
			day: tr('Day'),
			month: tr('Month'),
			previous: tr('Back'),
			next: tr('Next'),
			yesterday: tr('Yesterday'),
			tomorrow: tr('Tomorrow'),
			today: tr('Today'),
			agenda: tr('Agenda'),
		  
			noEventsInRange: tr('There are no events in this range.'),
		  
			showMore: total => `+${total} ${tr('more')}`,
		  }

		const use12hr = this.context.calendar.clock === 0;
		const timeFormat = use12hr ? 'h:mm A' : 'HH:mm';
		const timeGutterFormat = use12hr ? 'h A' : 'HH:mm';
		return (
            <div className={"CalendarWrapper padded"}>
				{!noSidebar && <div className="CalendarSidebar">
					<div className={`section ${projectsExpanded && 'flex'} ${projectsExpanded && usersExpanded && 'margin-bottom'}`}>
						<div onClick={this.toggleProjectSection} className={`header ${!projectsExpanded && 'smallBottomPadding'}`}>
							{this.tr('Draggable Projects')}
							{projectsExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
						</div>
						{projectsExpanded && <>
							<div className="treeDropSearchArea">
								<div className="actions">
									<div onClick={this.showProjects}>{this.tr('Show my projects')}</div> 
									<div onClick={() => this.props.onSidebarProjectsChanged([])}>{this.tr('Clear')}</div> 
								</div>
								<ProjectTreeDropdown
                            		name="project" // TODO: Onko tarpeellinen?
									label={this.tr("Add project to list")}
									value={undefined}
									filterIds={_.map(this.props.sidebarProjects, 'id')}
									queryParameters={{ noLockedCustomers: true, right: 'read', company: this.props.preventMulticompanyHourentries ? this.context.userObject.companies_id : undefined, context: 'workhours' }}
									treeDropdownProps={{
										activateBestMatch: true,
										highlightMatches: true,
										useTooltip: true,
										growOptionContainer: true,
										inputClassName: "selectProjectInput",
										usePopper: true
									}}
									onlyOwnProjects={false}
									onSelect={this.onSelectProject} />
							</div>
							<SortableList
								className="scrollList"
								items={this.props.sidebarProjects}
								component={SidebarProject}
								onDragStart={this.onProjectDragStart}
								hours={this.getProjectHours}
								onHide={this.props.onSidebarRemoveProject}
								onOrderChanged={this.onOrderChanged}
								hoursSheetProject={this.props.hoursSheetProject}
								onChangeColor={this.props.onChangeColorProject}
								lastEntry={lastEntry}
								dragType="project"
								filter={sidebarFilter}
							/>
						</>}
					</div>
					{(checkPrivilege("workhours", "read_full") || checkPrivilege("workhours", "write_full") || checkPrivilege("workhours", "read_superior") || checkPrivilege("workhours", "delete_full")) && <div className={`section ${usersExpanded && 'flex'}`}>
						<div onClick={this.toggleUserSection} className="header">
							{this.tr('Team Members')}
							{usersExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
						</div>
						{usersExpanded && <>
							<div className="searchArea">
								<TextBoxSuggestUser
									company={userObject.companies_id}
									name="user"
									onlySubjects={!checkPrivilege("workhours", "read_full") && !checkPrivilege("workhours", "delete_full") && checkPrivilege("workhours", "read_superior")}
									placeholder= {this.tr("Add user or team")}
									onSelectSuggestion={this.addUser}
									currentUsers={_.map(this.props.sidebarUsers, 'id')}
									/>
							</div>
							<div className="sidebar-user-list">
							{
								this.props.sidebarUsers.map(el => {
									const uid = (el.id + "").replace(/[^0-9]/g,"");
									const balance = workhourbalances[uid] ?? false;

									return <div key={el.id} className={cn("user", getColorClass(el, 'user'))}>
										<ColorBall onChangeColor={(c) => this.props.onChangeColorUser(el, c)} />
										<TaimerAvatar
											id={uid}
											name={el.label}
											color={el.color}
										/>
										<div className="name-container">
											<div className="name">
												{el.label}{el.user_ids ? 
												(el.type == 0 ? ' ' + this.tr("(Groups)") : (el.type == 2 
													? ' ' + this.tr("(Teams)") : '')) : ''}
												
											</div>
											<div className="title">
												{el.title}
											</div>
											{balance && <div className="balance">
												{this.tr("Balance")} {(Number(balance.balance) || 0).toFixed(2)} {this.tr("h")}
												{overtime_hours_activated && <> / {this.tr("Overtime")} {Number(balance.overtime_balance).toFixed(2)} {this.tr("h")}</>}
											</div>}
										</div>
										{el.assignee > 0 && <div className={"assigned"}>
											{this.tr('Assign')}
										</div>}
										<ContextMenu buttonProps={button} variant="outlined" className="list-menu cell row-menu" label="..." size="large" placement={"bottom-start"}>
											{
												timeTracker.resourcingEnabled && this.context.addons && this.context.addons.resourcing && checkPrivilege("projects", "project_resourcing_write") && (
												el.assignee > 0  ?
												(<MenuItem
													onClick={() => this.changeTaskUserAssign(el, false)}>
														<DoNotAssignTaskToUserIcon className="taskToUser" /> {this.tr("Don't assign Tasks to User")}
												</MenuItem>)											
												:
												(<MenuItem
													onClick={() => this.changeTaskUserAssign(el, true)}>
													<AssignTaskToUserIcon className="taskToUser" /> {this.tr(' Assign Tasks to User')}
												</MenuItem>)
												)
											}		                            	
											<MenuItem
												className="delete"
												onClick={() => this.hideUser(el)}>
											<RemoveIcon className="Delete" />{ this.tr('Remove From List')}
											</MenuItem>
										</ContextMenu>
									</div>
								})
							}
							</div>
						</>}
					</div>}

				</div>}
				<div className={cn("CalendarMain", "TimeTrackerTimeslots_" + slots)}>
				<CalendarContext.Provider value={{ hoverLocation: this.state.hoverLocation, setHoverLocation: this.setHoverLocation, cancelHoverLocation: this.cancelHoverLocation, createEvent: this.showEventCreate, calendarAccuracy: this.accurary, tr: this.tr }}>
						<DnDCalendar
							formats={{
								timeGutterFormat: isExact ? timeGutterFormat : timeFormat,
								monthHeaderFormat: 'MMMM YYYY',
								weekdayFormat: "ddd",
								dateFormat: "DD",
								dayFormat: "DD ddd",
								dayHeaderFormat: 'dddd MMM DD',
								dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
									const startMonth = localizer.format(start, "MMMM", culture);
									const endMonth = localizer.format(end, "MMMM", culture);
									if (startMonth !== endMonth) {
										return `${startMonth} ${localizer.format(start, "DD", culture)} - ${endMonth} ${localizer.format(end, "DD", culture)}`
									}
									return `${startMonth} ${localizer.format(start, "DD", culture)} - ${localizer.format(end, "DD", culture)}`
								},
								eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
									return `${localizer.format(start, timeFormat, culture)} - ${localizer.format(end, timeFormat, culture)}`
								},
								selectRangeFormat: ({ start, end }, culture, localizer) => {
									return `${localizer.format(start, timeFormat, culture)} - ${localizer.format(end, timeFormat, culture)}`
								},
							}}
							min={this.props.startHour}
							max={this.props.endHour}
							selectable={this.props.demoMode || (this.state.selectable && hasPrivilege("workhours", "write"))}
							resizable={!this.props.demoMode && this.state.resizable}
							draggableAccessor={(event) => !event.saving && this.state.resizable && event.editable && !this.props.demoMode}
							date={this.state.currentDate}
							defaultView={this.state.currentView}
							events={events}
							onEventDrop={this.onEventDrop}
							onEventResize={this.onEventResize}
							localizer={this.localizer}
							culture={this.context.calendar.localeName}
							components={{
								toolbar: CalendarToolbar,
								event: EventWrapper,
								timeSlotWrapper: TimeSlotWrapperWithContext,
								dateCellWrapper: DateCellWrapper,
								day: {
									header: HeaderWrapper,
								},
								week: {
									header: HeaderWrapper,
								},
								month: {
									dateHeader: DateHeader
								}
							}}
							eventPropGetter={this.getEventProps}
							onNavigate={this.onNavigate}
							onView={this.onViewChange}
							onSelectSlot={this.onSelectSlot}
							onDoubleClickEvent={this.onDoubleClick}
							onSelectEvent={this.onClick}
							showMultiDayTimes
							views={['month', 'week', 'day']}
							step={this.accurary}
							timeslots={slots}
							messages={messages}
							dayLayoutAlgorithm="no-overlap"
							onSelecting={this.onSelectingEvent}
							slotPropGetter={(date) => ({
								...(date.getHours() == this.state.hoverLocation?.hours && date.getMinutes() == this.state.hoverLocation?.minutes && {
									className: 'additional-time-slot',
								}),
							})}

						/>
					</CalendarContext.Provider>
				</div>
			</div>
        );
	}

	getChildContext() {
        const { userObject: { usersId } } = this.context;
		const { sidebarUsers } = this.props;

		return {
			timetracker: {
				createNewEvent: this.quickCreateEvent,
				loading: this.props.loading,
				workdays: this.props.workdays,
				events: this.props.events,
				tr: this.tr,
				usersId: usersId,
				onlyOwnVisible: sidebarUsers.length === 0 || (sidebarUsers.length === 1 && (sidebarUsers[0].id == usersId || sidebarUsers[0].id == `u-${usersId}`))
			},
			calendar: {
				clock: this.context.calendar.clock
			}
		}
	}
}

const TimeSlotWrapperWithContext = (props) => {
	return (<CalendarContext.Consumer>{calendarContext => (<TimeSlotWrapper {...props} {...calendarContext} />)}</CalendarContext.Consumer>)
}

CalendarView.childContextTypes = {
	timetracker: PropTypes.object,
	calendar: PropTypes.object
};

export default withSnackbar(CalendarView)
